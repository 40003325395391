@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Italiana&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@400;500;600;700&display=swap");

@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@300;400;500&display=swap');


@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500&display=swap');

body {
	height: 100%;
	margin: 0;
	font-family: 'Montserrat', sans-serif;
}

html {
	margin-left: calc(100vw - 100%);
	height: 100vh;
}

h1,
h2 {
	margin: 0;
	padding: 0;
	font-weight: 300;
	line-height: 30px;
}

p {
	margin: 0;
	padding: 0;
	font-weight: 300;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}
