a {
  align-self: center;
  font-size: 24px;
  text-decoration: none;
  color: black;
  transition: color 0.3s;
  font-weight: 300;
  margin: 0 16px;
}

a:hover {
  color: #4f8928;
}

.active {
  color: #4f8928;
}
